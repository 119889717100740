import React, { Component } from "react";
import { Tab, Tabs } from "@reactivepad/blueprint-core";
import { Editor } from "./plugins/loader";
import {
  projectManagementTemplate,
  monthlySalesRecap,
  agencyMonthlyReport
} from "./plugins/mainTemplates";

class Templates extends Component {
  render() {
    return (
      <Tabs animate id="templates" key="horizontal" renderActiveTabPanelOnly>
        {[
          {
            id: "pm",
            title: "Project Management",
            template: projectManagementTemplate
          },
          {
            id: "msr",
            title: "Monthly Sales Recap",
            template: monthlySalesRecap
          },
          {
            id: "amr",
            title: "Agency Monthly Report KPIs",
            template: agencyMonthlyReport
          }
        ].map(({ id, title, template }) => (
          <Tab
            id={id}
            key={id}
            title={title}
            panel={<Editor id={id} value={template} />}
          />
        ))}
      </Tabs>
    );
  }
}

export default Templates;
