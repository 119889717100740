export const projectManagementTemplate = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Introduction" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Launching version 2.0 of our XYZ product." }
      ]
    },
    {
      type: "heading",
      attrs: { level: 4 },
      content: [{ type: "text", text: "Themes" }]
    },
    {
      type: "bullet_list",
      content: [
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text:
                    "Great company-wide effort to work towards a common goal"
                }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text:
                    "Could've done a better job with getting reporters lined up for launch"
                }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text:
                    "Most features were launched with ample time for testing, but should allow more time for testing in the future"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "heading",
      attrs: { level: 4 },
      content: [{ type: "text", text: "Tasks" }]
    },
    {
      type: "reactivepad_table",
      attrs: {
        id: "zchjs",
        type: "table",
        name: "tasks",
        columns: [
          {
            id: "zputo",
            name: "Person",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: null,
            width: 150
          },
          {
            id: "wywqt",
            name: "Task",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: null,
            width: 392
          },
          {
            id: "bdzfh",
            name: "Status",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: {
              type: "select",
              config: {
                values: [
                  { value: "To Do" },
                  { value: "In Progress" },
                  { value: "Done" },
                  { value: "On Hold" },
                  { value: "Abandondoned" }
                ]
              }
            },
            width: 150
          }
        ],
        rows: [
          {
            id: "beclb",
            type: "row",
            cells: [
              {
                id: "cnqug",
                type: "cell",
                column: "zputo",
                value: "John",
                formatted: "John"
              },
              {
                id: "xusax",
                type: "cell",
                column: "wywqt",
                value: "Set stretch goals",
                formatted: "Set stretch goals"
              },
              {
                id: "trcsn",
                type: "cell",
                column: "bdzfh",
                value: "To Do",
                formatted: "To Do"
              }
            ],
            height: 27
          },
          {
            id: "tkrrd",
            type: "row",
            cells: [
              {
                id: "dvqrj",
                type: "cell",
                column: "zputo",
                value: "Felix",
                formatted: "Felix"
              },
              {
                id: "crqdw",
                type: "cell",
                column: "wywqt",
                value: "Develop stories with reporters",
                formatted: "Develop stories with reporters"
              },
              {
                id: "sjzbp",
                type: "cell",
                column: "bdzfh",
                value: "In Progress",
                formatted: "In Progress"
              }
            ],
            height: 27
          },
          {
            id: "iqdlh",
            type: "row",
            cells: [
              {
                id: "cceti",
                type: "cell",
                column: "zputo",
                value: "John",
                formatted: "John"
              },
              {
                id: "zwyjt",
                type: "cell",
                column: "wywqt",
                value:
                  "Consult with product team to figure out launch messaging",
                formatted:
                  "Consult with product team to figure out launch messaging"
              },
              {
                id: "ndtbh",
                type: "cell",
                column: "bdzfh",
                value: "To Do",
                formatted: "To Do"
              }
            ],
            height: 27
          },
          {
            id: "skwuf",
            type: "row",
            cells: [
              {
                id: "fdaki",
                type: "cell",
                column: "zputo",
                value: "Andrew",
                formatted: "Andrew"
              },
              {
                id: "iwbwv",
                type: "cell",
                column: "wywqt",
                value:
                  "Communicate to entire company about new feature development",
                formatted:
                  "Communicate to entire company about new feature development"
              },
              {
                id: "bhlmn",
                type: "cell",
                column: "bdzfh",
                value: "On Hold",
                formatted: "On Hold"
              }
            ],
            height: 27
          },
          {
            id: "jtetc",
            type: "row",
            cells: [
              {
                id: "qseon",
                type: "cell",
                column: "zputo",
                value: "Felix",
                formatted: "Felix"
              },
              {
                id: "gfcbr",
                type: "cell",
                column: "wywqt",
                value: "Make the page navigation delightful",
                formatted: "Make the page navigation delightful"
              },
              {
                id: "bokti",
                type: "cell",
                column: "bdzfh",
                value: "Done",
                formatted: "Done"
              }
            ],
            height: 27
          },
          {
            id: "lnmki",
            type: "row",
            cells: [
              {
                id: "sscxx",
                type: "cell",
                column: "zputo",
                value: "Katie",
                formatted: "Katie"
              },
              {
                id: "bfnfc",
                type: "cell",
                column: "wywqt",
                value: "Prepare docs",
                formatted: "Prepare docs"
              },
              {
                id: "rpwel",
                type: "cell",
                column: "bdzfh",
                value: "Done",
                formatted: "Done"
              }
            ],
            height: 27
          }
        ],
        showFooter: false
      }
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [
        { type: "text", text: "Tasks accomplished: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "eprry",
            type: "formula",
            name: "formula1",
            value: '=COUNTIF(tasks[Status], "Done")',
            formatted: 2,
            formatter: null
          }
        },
        { type: "text", text: "  / " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "uimjk",
            type: "formula",
            name: "formula2",
            value: "=ROWS(tasks[Task])",
            formatted: 6,
            formatter: null
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [
        { type: "text", text: "People who have completed their tasks: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "abpkl",
            type: "formula",
            name: "formula3",
            value: '= JOIN(FILTER(tasks[Person], tasks[Status], "Done"), ", ")',
            formatted: "Felix, Katie",
            formatter: null
          }
        },
        { type: "text", text: " ." }
      ]
    },
    { type: "paragraph" },
    { type: "paragraph" },
    { type: "paragraph" }
  ]
};

export const monthlySalesRecap = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 1 },
      content: [{ type: "text", text: "Stats" }]
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [
        { type: "hard_break" },
        { type: "text", text: "January, 2019 ⌚️" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Beginning of month Total MRR: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "abuna",
            type: "formula",
            name: "start_mrr",
            value: 20000,
            formatted: "20,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "New MRR: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "zimnf",
            type: "formula",
            name: "new_mrr",
            value: 2500,
            formatted: "2,500",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Churn and Downgrades: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "razdt",
            type: "formula",
            name: "churn",
            value: 300,
            formatted: "300",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Upgrades: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "oeomf",
            type: "formula",
            name: "upgrades",
            value: 500,
            formatted: "500",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Net New MRR: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "bgfmw",
            type: "formula",
            name: "net_mrr",
            value: "=new_mrr+upgrades-churn",
            formatted: "2,700",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "End of month Total MRR: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "cwtlh",
            type: "formula",
            name: "total_mrr",
            value: "=start_mrr+net_mrr",
            formatted: "22,700",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "New Customers: 24" }]
    },
    { type: "paragraph", content: [{ type: "text", text: "Upgrades: 3" }] },
    { type: "paragraph" },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ":" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Last month was closed with MRR " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "fpjph",
            type: "formula",
            name: "formula1",
            value: "=total_mrr",
            formatted: "22,700",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $ which is " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "gzner",
            type: "formula",
            name: "formula2",
            value: "=net_mrr",
            formatted: "2,700",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $ higher than last year. The growth rate is " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "kqtxe",
            type: "formula",
            name: "growth",
            value: "=net_mrr/total_mrr",
            formatted: "12%",
            formatter: "0%"
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" }
      ]
    },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "Goal 🥅" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Goal: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "oldmz",
            type: "formula",
            name: "goal",
            value: 2000,
            formatted: "2,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Net New MRR: $ Percent of goal: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "kmbfv",
            type: "formula",
            name: "formula3",
            value: "=net_mrr/goal",
            formatted: "135%",
            formatter: "0%"
          }
        },
        { type: "text", text: " %" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "hard_break" },
        { type: "text", text: "Quarterly goal " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "piotj",
            type: "formula",
            name: "quarter_g",
            value: 12000,
            formatted: "12,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Remaining for quarterly goal: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "birah",
            type: "formula",
            name: "rem_quar",
            value: "=quarter_g-net_mrr",
            formatted: "9,300",
            formatter: "0,0"
          }
        },
        { type: "text", text: " $" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Percentage of quarterly goal: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "snkvx",
            type: "formula",
            name: "quar_perc",
            value: "=net_mrr/quarter_g",
            formatted: "23%",
            formatter: "0%"
          }
        },
        { type: "text", text: " %" }
      ]
    },
    { type: "paragraph" },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ":" }
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "After the first month of 2019, we already achieved "
        },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "qjepv",
            type: "formula",
            name: "formula5",
            value: "=quar_perc",
            formatted: "23%",
            formatter: "0%"
          }
        },
        { type: "text", text: " of our quarter goal." }
      ]
    },
    { type: "paragraph" },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [{ type: "text", text: "Funnel Metrics 📈" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Traffic: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "pknnl",
            type: "formula",
            name: "traffic",
            value: 12000,
            formatted: "12,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "MQLs: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "twbnx",
            type: "formula",
            name: "mql",
            value: 100,
            formatted: "100",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "bullet_list",
      content: [
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "conversion: " },
                {
                  type: "reactivepad_formula",
                  attrs: {
                    id: "xomzq",
                    type: "formula",
                    name: "trafficmql",
                    value: "=mql/traffic",
                    formatted: "1%",
                    formatter: "0%"
                  }
                },
                { type: "text", text: " %" }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "SQLs: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "tkjwu",
            type: "formula",
            name: "sql",
            value: 35,
            formatted: "35",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "bullet_list",
      content: [
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "conversion:  " },
                {
                  type: "reactivepad_formula",
                  attrs: {
                    id: "jvofv",
                    type: "formula",
                    name: "mql_sql",
                    value: "=sql/mql",
                    formatted: "35%",
                    formatter: "0%"
                  }
                },
                { type: "text", text: " %" }
              ]
            }
          ]
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Closed Won Deals: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "vteah",
            type: "formula",
            name: "won",
            value: 24,
            formatted: "24",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "bullet_list",
      content: [
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "conversion:  " },
                {
                  type: "reactivepad_formula",
                  attrs: {
                    id: "zzkwq",
                    type: "formula",
                    name: "won_sql",
                    value: "=won/sql",
                    formatted: "69%",
                    formatter: "0%"
                  }
                },
                { type: "text", text: " %" }
              ]
            },
            { type: "paragraph" }
          ]
        }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ":" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "This month we won " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "hpjcs",
            type: "formula",
            name: "formula6",
            value: "=won",
            formatted: "24",
            formatter: null
          }
        },
        {
          type: "text",
          text: " deals and acquired record traffic ever, which is "
        },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "cejpv",
            type: "formula",
            name: "formula7",
            value: "=traffic",
            formatted: "12,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    { type: "paragraph" },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Deal by Deal" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "hard_break", marks: [{ type: "strong" }] },
        { type: "text", marks: [{ type: "strong" }], text: "New Customers" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Company A - " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "trebx",
            type: "formula",
            name: "users_a",
            value: 156,
            formatted: "156",
            formatter: "0,0"
          }
        },
        { type: "text", text: "  users - $" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "fqzeg",
            type: "formula",
            name: "hajs_a",
            value: 15000,
            formatted: "15,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " /year" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Industry - eCommerce" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Sales Cycle - " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "kakle",
            type: "formula",
            name: "days_a",
            value: 18,
            formatted: "18",
            formatter: "0,0"
          }
        },
        { type: "text", text: "  days" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Use Case - Operations" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ":" },
        { type: "hard_break" },
        {
          type: "text",
          text:
            " Almost lost to a competitor. New machine learning features put us over the edge."
        }
      ]
    },
    { type: "paragraph" },
    { type: "horizontal_rule" },
    { type: "paragraph" },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Company B - " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "aoxyj",
            type: "formula",
            name: "users_b",
            value: 240,
            formatted: "240",
            formatter: "0,0"
          }
        },
        { type: "text", text: "  users - $" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ckjgm",
            type: "formula",
            name: "hajs_b",
            value: 2800,
            formatted: "2,800",
            formatter: "0,0"
          }
        },
        { type: "text", text: " /mo" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Industry - Agency" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Sales Cycle - " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "xeqvu",
            type: "formula",
            name: "days_b",
            value: 23,
            formatted: 23,
            formatter: null
          }
        },
        { type: "text", text: "  days" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Use Case - Agency, Expansion" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ": " },
        { type: "hard_break" },
        {
          type: "text",
          text:
            "Launching two new international locations, looking for Tettra for consistency"
        }
      ]
    },
    { type: "paragraph" },
    { type: "horizontal_rule" },
    { type: "paragraph" },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Company C - " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "cgcbx",
            type: "formula",
            name: "users_c",
            value: 10,
            formatted: "10",
            formatter: "0,0"
          }
        },
        { type: "text", text: " users - $ " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ggnaj",
            type: "formula",
            name: "hajs_c",
            value: 50000,
            formatted: "50,000",
            formatter: "0,0"
          }
        },
        { type: "text", text: " /mo" }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Industry - Finance" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Sales Cycle " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ywrnp",
            type: "formula",
            name: "days_c",
            value: 87,
            formatted: "87",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " }
      ]
    },
    {
      type: "paragraph",
      content: [{ type: "text", text: "Use Case - Operations" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "Notes" },
        { type: "text", text: ":" },
        { type: "hard_break" },
        {
          type: "text",
          text: " Extremely long closing time, but most valuable clients"
        }
      ]
    },
    { type: "paragraph" },
    { type: "horizontal_rule" },
    { type: "paragraph" },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Notes 🗯" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Our total yearly income is $" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ejvpk",
            type: "formula",
            name: "hajs_total",
            value: "=hajs_a+hajs_b*12+hajs_c*12",
            formatted: "648,600",
            formatter: "0,0"
          }
        },
        { type: "text", text: " where majority of the income (" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "uoqor",
            type: "formula",
            name: "c_total",
            value: "=(hajs_c*12)/hajs_total",
            formatted: "93%",
            formatter: "0%"
          }
        },
        { type: "text", text: " ) comes from Company C that brings us $" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "mrsrs",
            type: "formula",
            name: "hajs_ctot",
            value: "=hajs_c*12",
            formatted: "600,000",
            formatter: "0,0"
          }
        },
        {
          type: "text",
          text: ". Our average closing time within all of the companies is "
        },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "xsqaw",
            type: "formula",
            name: "days_total",
            value: "=(days_a+days_b+days_c)/3",
            formatted: "43",
            formatter: "0,0"
          }
        },
        { type: "text", text: " days." }
      ]
    }
  ]
};

export const agencyMonthlyReport = {
  type: "doc",
  content: [
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Overview: June 2019" }]
    },
    {
      type: "bullet_list",
      content: [
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "We focused mostly on " },
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: "onsite changes "
                },
                { type: "text", text: "and improvements." }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "We did the " },
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: "error messages"
                },
                {
                  type: "text",
                  text:
                    " in checkout and account pages, distinguished between backend and frontend errors and did the proper styling."
                }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "Beside, we change the design in the " },
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: "main drop-down"
                },
                { type: "text", text: " menu on the homepage." }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                { type: "text", text: "We also did some" },
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: " SEO add-ons"
                },
                {
                  type: "text",
                  text: " to make the site more visible and searchable."
                }
              ]
            }
          ]
        },
        {
          type: "list_item",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text:
                    "SALE also started in June, so there was a need to create and add"
                },
                {
                  type: "text",
                  marks: [{ type: "strong" }],
                  text: " sales landing pages."
                }
              ]
            }
          ]
        }
      ]
    },
    { type: "paragraph" },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [{ type: "text", text: "Sales summary" }]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "In " },
        { type: "text", marks: [{ type: "em" }], text: "June 2016" },
        { type: "text", text: " we have had " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "aeusj",
            type: "formula",
            name: "orders",
            value: 1403,
            formatted: "1,403",
            formatter: "0,0"
          }
        },
        { type: "text", text: "  orders and in total " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "zjyvq",
            type: "formula",
            name: "products",
            value: 1561,
            formatted: "1,561",
            formatter: "0,0"
          }
        },
        {
          type: "text",
          text: "  products sold. Traffic in this month was on the level of "
        },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "szuyl",
            type: "formula",
            name: "traffic",
            value: 125225,
            formatted: "125,225",
            formatter: "0,0"
          }
        },
        { type: "text", text: " unique visitors. " }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "This means that conversion is " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "lrhfd",
            type: "formula",
            name: "CR",
            value: "=products/traffic",
            formatted: "1%",
            formatter: "0%"
          }
        },
        { type: "text", text: " . In June, the total revenue is " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ycepu",
            type: "formula",
            name: "revenue",
            value: 1901792,
            formatted: "1,901,792.00",
            formatter: "0,0.00"
          }
        },
        { type: "text", text: " kr. Average Order Value equals " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "yisut",
            type: "formula",
            name: "AOV",
            value: "=revenue/products",
            formatted: "1,218.32",
            formatter: "0,0.00"
          }
        },
        { type: "text", text: "  kr." }
      ]
    },
    { type: "paragraph" },
    {
      type: "paragraph",
      content: [
        { type: "text", marks: [{ type: "em" }], text: "New Customers: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "ydxss",
            type: "formula",
            name: "customers",
            value: 1534,
            formatted: "1,534.00",
            formatter: "0,0.00"
          },
          marks: [{ type: "em" }]
        },
        { type: "text", marks: [{ type: "em" }], text: " " }
      ]
    },
    {
      type: "heading",
      attrs: { level: 2 },
      content: [
        { type: "hard_break" },
        { type: "text", marks: [{ type: "strong" }], text: "Outcome" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "hard_break" },
        { type: "text", text: "Traffic: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "shfcd",
            type: "formula",
            name: "formula1",
            value: "=traffic",
            formatted: "125225",
            formatter: null
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Conversions: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "rzkcz",
            type: "formula",
            name: "formula2",
            value: "=CR",
            formatted: "1%",
            formatter: "0%"
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Revenue:" },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "wcsij",
            type: "formula",
            name: "formula3",
            value: "=revenue",
            formatted: "1,901,792.00",
            formatter: "0,0.00"
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "Average Order Value: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "nggzk",
            type: "formula",
            name: "aov",
            value: "=AOV",
            formatted: "1,218.32",
            formatter: "0,0.00"
          }
        },
        { type: "text", text: "  kr" },
        { type: "hard_break" }
      ]
    },
    {
      type: "paragraph",
      content: [
        { type: "text", text: "New Customers: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "wlkii",
            type: "formula",
            name: "formula4",
            value: "=customers",
            formatted: "1,534",
            formatter: "0,0"
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" }
      ]
    },
    { type: "paragraph" },
    {
      type: "heading",
      attrs: { level: 3 },
      content: [
        { type: "text", marks: [{ type: "strong" }], text: "Key Takeaway" }
      ]
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          marks: [{ type: "strong" }],
          text: "Stats Year-on-Year"
        },
        { type: "hard_break" },
        { type: "text", text: "Traffic: " },
        {
          type: "reactivepad_formula",
          attrs: {
            id: "npipn",
            type: "formula",
            name: "traf",
            value: "=(122555/90900)*100%",
            formatted: "135%",
            formatter: "0%"
          }
        },
        { type: "text", text: " " },
        { type: "hard_break" },
        { type: "text", text: "Conversion Rate: same" },
        { type: "hard_break" },
        { type: "text", text: "Revenue: 18%" },
        { type: "hard_break" },
        { type: "text", text: "AOV: -15.28%" },
        { type: "hard_break" },
        { type: "text", text: "New Customers: -6,5%" },
        { type: "hard_break" },
        { type: "hard_break" },
        {
          type: "text",
          marks: [{ type: "strong" }],
          text: "Stats Month-on-Month"
        },
        { type: "hard_break" },
        { type: "text", text: "Traffic: 52%" },
        { type: "hard_break" },
        { type: "text", text: "Conversions: 13,7%" },
        { type: "hard_break" },
        { type: "text", text: "Conversion Rate: -4,6" },
        { type: "hard_break" },
        { type: "text", text: "Revenue:-0,7%" },
        { type: "hard_break" },
        { type: "text", text: "AOV: -0.6%" },
        { type: "hard_break" },
        { type: "text", text: "New Customers: -6,83" }
      ]
    },
    { type: "paragraph", content: [{ type: "hard_break" }] }
  ]
};
