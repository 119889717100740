const shadow = {
  boxShadow: "2px 2px 8px 0 rgba(0,0,0,.2)"
};

export const styles = {
  shadow,
  table: {
    ...shadow,
    margin: "20px 0",
    width: 800,
    outline: "none"
  },
  dataStructureImg: {
    ...shadow,
    margin: "10px 0",
    width: 150
  }
};
