import React, { Component } from "react";
import { styles } from "./styles";

class VideoWrapper extends Component {
  render() {
    const { src, style } = this.props;

    return (
      <video style={{ ...styles.table, style }} controls muted>
        <source src={src} type="video/mp4" />
      </video>
    );
  }
}

const awsUrl = "https://reactivepad.s3.eu-central-1.amazonaws.com/demos/";

export const VideoWrapperFirebase = ({ src, ...props }) => (
  <VideoWrapper {...props} src={`${awsUrl}${src}`} />
);

export default VideoWrapper;
