import React, { Component } from "react";
import ReactDOM from "react-dom";
import loadScript from "simple-load-script";
import * as ReactivepadCore from "@reactivepad/core";
import { Spinner } from "@reactivepad/blueprint-core";

const editorId = "rxp-ckeditor";
const pluginScriptUrl =
  "https://reactivepad.s3.eu-central-1.amazonaws.com/js/ckeditor4/ckeditor/ckeditor.js";

class CKEditor extends Component {
  state = {
    scriptsLoadingState: 0
  };

  setScriptsLoadingState = scriptsLoadingState => {
    this.setState({ scriptsLoadingState });
  };

  async componentDidMount() {
    window.React = React;
    window.ReactDOM = ReactDOM;
    window.ReactivepadCore = ReactivepadCore;

    try {
      this.setScriptsLoadingState(1);
      await loadScript(pluginScriptUrl);
      const { CKEDITOR } = window;

      if (!CKEDITOR) {
        this.setScriptsLoadingState(4);
        return;
      }
      this.setScriptsLoadingState(3);

      CKEDITOR.config.removeButtons =
        "Cut,Copy,Paste,Undo,Redo,Anchor,Underline,Strike,Subscript,Superscript";
      CKEDITOR.config.toolbarGroups = [
        { name: "insert" },
        { name: "document", groups: ["mode", "document", "doctools"] },
        { name: "clipboard", groups: ["clipboard", "undo"] },
        { name: "editing", groups: ["find", "selection", "spellchecker"] },
        { name: "forms" },
        { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
        {
          name: "paragraph",
          groups: ["list", "indent", "blocks", "align"]
        },
        { name: "links" },
        { name: "styles" },
        { name: "colors" },
        { name: "tools" },
        { name: "others" }
      ];
      CKEDITOR.replace(editorId, {
        height: "400px"
      });
    } catch (e) {
      this.setScriptsLoadingState(4);
    }
  }

  render() {
    const { scriptsLoadingState } = this.state;

    return (
      <div id={editorId}>
        {scriptsLoadingState === 1 && <Spinner />}
        {scriptsLoadingState === 4 && <h4>Unexpected error occurred.</h4>}
      </div>
    );
  }
}

export default CKEditor;
