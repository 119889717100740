import { Playground } from "docz";
import DraftJS from "../../../../../src/plugins/DraftJS";
import { Editor } from "../../../../../src/plugins/loader";
import CKEditor from "../../../../../src/plugins/CKEditor";
import React from 'react';
export default {
  Playground,
  DraftJS,
  Editor,
  CKEditor,
  React
};