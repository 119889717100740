import React, { Component } from "react";
import {
  convertFromRaw,
  CompositeDecorator,
  Editor,
  EditorState,
  Modifier,
  AtomicBlockUtils,
  RichUtils,
  getDefaultKeyBinding
} from "draft-js";
import { draftjs } from "./samples";
import {
  buildPlugin,
  ENTITY_TYPES,
  syncStoreWithDoc,
  formulaDecoratorStrategy
} from "@reactivepad/draftjs";
import "./draftjs.css";

class DraftEditor extends Component {
  constructor(props) {
    super(props);

    this.reactivepadPlugin = buildPlugin({
      toggleReadOnly: this.toggleReadOnly
    });

    const {
      components: { Formula }
    } = this.reactivepadPlugin;

    const decorator = new CompositeDecorator([
      {
        strategy: formulaDecoratorStrategy,
        component: props => (
          <Formula {...props} updateContentState={this.updateContentState} />
        )
      }
    ]);

    const blocks = convertFromRaw(draftjs);

    this.state = {
      editorState: EditorState.createWithContent(blocks, decorator),
      readOnly: false
    };

    this.focus = () => this.editor.focus();
    this.onChange = editorState => this.setState({ editorState });
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.formulaTimeoutId);
    this.reactivepadPlugin.destroy();
  }

  toggleReadOnly = (readOnly = !this.state.readOnly) => {
    this.setState({ readOnly });
  };

  onChange = (editorState, skipSyncWithStore) => {
    const prevContentState = this.state.editorState.getCurrentContent();
    this.setState({ editorState }, () => {
      if (
        !skipSyncWithStore &&
        prevContentState !== this.state.editorState.getCurrentContent()
        // don't sync when only selection has changed
      ) {
        syncStoreWithDoc(this.reactivepadPlugin.store, this.state.editorState);
      }
    });
  };

  updateContentState = (
    contentState,
    skipSyncWithStore, // sometimes sync is unnecessary
    editorState = this.state.editorState
  ) => {
    const newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    this.onChange(newEditorState, skipSyncWithStore);
  };

  insertFormula = () => {
    const { editorState } = this.state;

    const formula = this.reactivepadPlugin.store.createAndAddFormula();
    const whitespace = " ";
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.replaceText(
      contentState,
      editorState.getSelection(),
      whitespace
    );

    const contentStateWithEntity = contentState.createEntity(
      ENTITY_TYPES.FORMULA,
      "IMMUTABLE",
      formula.serialize()
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selectionState = editorState.getSelection();
    const contentStateWithFormula = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState.merge({
        focusOffset: selectionState.getAnchorOffset() + whitespace.length
      }),
      entityKey
    );

    this.updateContentState(contentStateWithFormula, true);

    this.formulaTimeoutId = setTimeout(() => {
      formula.toggleEditing(true);
    }, 5);
  };

  insertTable = () => {
    const { editorState } = this.state;

    const table = this.reactivepadPlugin.store.createAndAddTable();
    const whitespace = " ";
    const contentStateWithEntity = editorState
      .getCurrentContent()
      .createEntity(ENTITY_TYPES.TABLE, "IMMUTABLE", table.serialize());
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    let newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    newEditorState = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      whitespace
    );

    this.onChange(newEditorState);
  };

  blockRenderer = block => {
    const entityKey = block.getEntityAt(0);

    if (!entityKey) {
      return null;
    }

    const entity = this.state.editorState
      .getCurrentContent()
      .getEntity(entityKey);
    const type = entity.getType();

    if (type !== ENTITY_TYPES.TABLE) {
      return null;
    }

    const { Table } = this.reactivepadPlugin.components;

    return {
      component: props => (
        <Table {...props} updateContentState={this.updateContentState} />
      ),
      editable: false
    };
  };

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    switch (e.keyCode) {
      case 9: // TAB
        const newEditorState = RichUtils.onTab(
          e,
          this.state.editorState,
          4 /* maxDepth */
        );
        if (newEditorState !== this.state.editorState) {
          this.onChange(newEditorState);
        }
        return;
      default:
        break;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  render() {
    const { editorState, readOnly } = this.state;

    return (
      <div className="RichEditor-root">
        <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
          onNewFormula={this.insertFormula}
          onNewTable={this.insertTable}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
        <div className="RichEditor-editor" onClick={this.focus}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            blockRendererFn={this.blockRenderer}
            readOnly={readOnly}
            customStyleMap={styleMap}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            ref={ref => (this.editor = ref)}
            spellCheck={false}
          />
        </div>
      </div>
    );
  }
}

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2
  }
};

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const FormulaIcon = ({ children }) => (
  <span
    style={{
      fontWeight: 500,
      color: "#000"
    }}
  >
    {children}
  </span>
);

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {[
        {
          label: <FormulaIcon>New Formula</FormulaIcon>,
          onClick: props.onNewFormula
        },
        {
          label: <FormulaIcon>New Table</FormulaIcon>,
          onClick: props.onNewTable
        }
      ].map(({ label, onClick }, i) => (
        <StyleButton key={i} active={true} label={label} onToggle={onClick} />
      ))}
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" }
];

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default DraftEditor;
